<template>
  <!-- ======= Mobile nav toggle button ======= -->
  <!-- <button type="button" class="mobile-nav-toggle d-xl-none"><i class="bi bi-list mobile-nav-toggle"></i></button> -->
    <i class="bi bi-list mobile-nav-toggle d-xl-none"></i>
  <!-- ======= Header ======= -->
    <header id="header" class="d-flex flex-column justify-content-center">
        <NavbarComponent/>
    </header><!-- End Header -->

    <main id="main">
        <HeroComponent/>
        <AboutComponent/>
        <FactsComponent/>
        <SkillsComponent/>
        <ResumeComponent/>
        <PortfolioComponent/>
        <ServicesComponent/>
        <TestimonialsComponent/>
        <ContactComponent/>
    </main><!-- End #main -->
    <FooterComponent/>


    <div id="preloader"></div>
    <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
            class="bi bi-arrow-up-short"></i></a>
</template>

<script>
import '@/assets/vendor/aos/aos.css';
import '@/assets/vendor/bootstrap/css/bootstrap.min.css';
import '@/assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '@/assets/vendor/boxicons/css/boxicons.min.css';
import '@/assets/vendor/glightbox/css/glightbox.min.css';
import '@/assets/vendor/swiper/swiper-bundle.min.css';
import '@/assets/css/style.css';

import Typed from '@/assets/vendor/typed.js/typed.min';
import '@/assets/vendor/waypoints/noframework.waypoints';
import Isotope from '@/assets/vendor/isotope-layout/isotope.pkgd.min';
import AOS from '@/assets/vendor/aos/aos';
import GLightbox from '@/assets/vendor/glightbox/js/glightbox.min';
import Swiper from '@/assets/vendor/swiper/swiper-bundle.min';
import NavbarComponent from "@/components/NavbarComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import HeroComponent from "@/components/HeroComponent.vue";
import AboutComponent from "@/components/AboutComponent.vue";
import FactsComponent from "@/components/FactsComponent.vue";
import SkillsComponent from "@/components/SkillsComponent.vue";
import ResumeComponent from "@/components/ResumeComponent.vue";
import PortfolioComponent from "@/components/PortfolioComponent.vue";
import ServicesComponent from "@/components/ServicesComponent.vue";
import TestimonialsComponent from "@/components/TestimonialsComponent.vue";
import ContactComponent from "@/components/ContactComponent.vue";
export default {
    name: 'HomeView',
    components: {
        ContactComponent,
        TestimonialsComponent,
        ServicesComponent,
        PortfolioComponent,
        ResumeComponent,
        SkillsComponent, FactsComponent, AboutComponent, HeroComponent, FooterComponent, NavbarComponent},
    mounted() {
        /**
         * Easy selector helper function
         */
        const select = (el, all = false) => {
            el = el.trim()
            if (all) {
                return [...document.querySelectorAll(el)]
            } else {
                return document.querySelector(el)
            }
        }

        /**
         * Easy event listener function
         */
        const on = (type, el, listener, all = false) => {
            let selectEl = select(el, all)
            if (selectEl) {
                if (all) {
                    selectEl.forEach(e => e.addEventListener(type, listener))
                } else {
                    selectEl.addEventListener(type, listener)
                }
            }
        }

        /**
         * Easy on scroll event listener
         */
        const onscroll = (el, listener) => {
            el.addEventListener('scroll', listener)
        }

        /**
         * Navbar links active state on scroll
         */
        let navbarlinks = select('#navbar .scrollto', true)
        const navbarlinksActive = () => {
            let position = window.scrollY + 200
            navbarlinks.forEach(navbarlink => {
                if (!navbarlink.hash) return
                let section = select(navbarlink.hash)
                if (!section) return
                if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
                    navbarlink.classList.add('active')
                } else {
                    navbarlink.classList.remove('active')
                }
            })
        }
        window.addEventListener('load', navbarlinksActive)
        onscroll(document, navbarlinksActive)

        /**
         * Scrolls to an element with header offset
         */
        const scrollto = (el) => {
            let elementPos = select(el).offsetTop
            window.scrollTo({
                top: elementPos,
                behavior: 'smooth'
            })
        }

        /**
         * Back to top button
         */
        let backtotop = select('.back-to-top')
        if (backtotop) {
            const toggleBacktotop = () => {
                if (window.scrollY > 100) {
                    backtotop.classList.add('active')
                } else {
                    backtotop.classList.remove('active')
                }
            }
            window.addEventListener('load', toggleBacktotop)
            onscroll(document, toggleBacktotop)
        }

        /**
         * Mobile nav toggle
         */
        // eslint-disable-next-line no-unused-vars
        on('click', '.mobile-nav-toggle', function(e) {
            select('body').classList.toggle('mobile-nav-active')
            this.classList.toggle('bi-list')
            this.classList.toggle('bi-x')
        })

        /**
         * Scrool with ofset on links with a class name .scrollto
         */
        on('click', '.scrollto', function(e) {
            if (select(this.hash)) {
                e.preventDefault()

                let body = select('body')
                if (body.classList.contains('mobile-nav-active')) {
                    body.classList.remove('mobile-nav-active')
                    let navbarToggle = select('.mobile-nav-toggle')
                    navbarToggle.classList.toggle('bi-list')
                    navbarToggle.classList.toggle('bi-x')
                }
                scrollto(this.hash)
            }
        }, true)

        /**
         * Scroll with ofset on page load with hash links in the url
         */
        window.addEventListener('load', () => {
            if (window.location.hash) {
                if (select(window.location.hash)) {
                    scrollto(window.location.hash)
                }
            }
        });

        /**
         * Preloader
         */
        let preloader = select('#preloader');
        if (preloader) {
            window.addEventListener('load', () => {
                preloader.remove()
            });
        }

        /**
         * Hero type effect
         */
        const typed = select('.typed')
        if (typed) {
            let typed_strings = typed.getAttribute('data-typed-items')
            typed_strings = typed_strings.split(',')
            new Typed('.typed', {
                strings: typed_strings,
                loop: true,
                typeSpeed: 100,
                backSpeed: 50,
                backDelay: 2000
            });
        }

        /**
         * Skills animation
         */
        let skilsContent = select('.skills-content');
        if (skilsContent) {
            // eslint-disable-next-line no-undef
            new Waypoint({
                element: skilsContent,
                offset: '80%',
                // eslint-disable-next-line no-unused-vars
                handler: function(direction) {
                    let progress = select('.progress .progress-bar', true);
                    progress.forEach((el) => {
                        el.style.width = el.getAttribute('aria-valuenow') + '%'
                    });
                }
            })
        }

        /**
         * Porfolio isotope and filter
         */
        window.addEventListener('load', () => {
            let portfolioContainer = select('.portfolio-container');
            if (portfolioContainer) {
                let portfolioIsotope = new Isotope(portfolioContainer, {
                    itemSelector: '.portfolio-item'
                });

                let portfolioFilters = select('#portfolio-flters li', true);

                on('click', '#portfolio-flters li', function(e) {
                    e.preventDefault();
                    portfolioFilters.forEach(function(el) {
                        el.classList.remove('filter-active');
                    });
                    this.classList.add('filter-active');

                    portfolioIsotope.arrange({
                        filter: this.getAttribute('data-filter')
                    });
                    portfolioIsotope.on('arrangeComplete', function() {
                        AOS.refresh()
                    });
                }, true);
            }

        });

        /**
         * Initiate portfolio lightbox
         */
            // eslint-disable-next-line no-unused-vars
        const portfolioLightbox = GLightbox({
            selector: '.portfolio-lightbox'
        });

        /**
         * Initiate portfolio details lightbox
         */
            // eslint-disable-next-line no-unused-vars
        const portfolioDetailsLightbox = GLightbox({
            selector: '.portfolio-details-lightbox',
            width: '90%',
            height: '90vh'
        });

        /**
         * Portfolio details slider
         */
        new Swiper('.portfolio-details-slider', {
            speed: 400,
            loop: true,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            }
        });

        /**
         * Testimonials slider
         */
        new Swiper('.testimonials-slider', {
            speed: 600,
            loop: true,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false
            },
            slidesPerView: 'auto',
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            }
        });

        /**
         * Animation on scroll
         */
        window.addEventListener('load', () => {
            AOS.init({
                duration: 1000,
                easing: 'ease-in-out',
                once: true,
                mirror: false
            })
        });
    }
}
</script>

<style>
@import '@/assets/css/google.css';







</style>